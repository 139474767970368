@import url(https://api.fontshare.com/css?f[]=satoshi@300,400,900&display=swap);
@import url(https://api.fontshare.com/css?f[]=chillax@500,600,700,900&display=swap);
@import url(https://api.fontshare.com/css?f[]=satoshi@300,400,700,900&display=swap);
@import url(https://api.fontshare.com/css?f[]=satoshi@300,400,900&display=swap);
@import url(https://api.fontshare.com/css?f[]=satoshi@300,400,900&display=swap);
div {
  margin: 0;
  font-weight: 400;
}

html {
  background-image: url("https://i.imgur.com/1AGWlJM.jpg");
  background-size: 175vw;
  background-attachment: fixed;
}

@media only screen and (max-width: 600px) {
  html {
    background-size: 200vh;
  }
}

.login-form > #loginName {
  color: black;
  text-decoration: none;
  font-family: "Chillax";
  font-weight: 600;
  font-size: 2vw;
  padding: 0 1% 0;
  opacity: 0.5;
  border: 1px solid black;
  margin-top: 13%;
  width: 15vw;
  position: fixed;
  left: 73%;

  top: 0;
  z-index: 100;
  background-color: #ffffff90;
}

.thyme-logo h1 {
  font-weight: 900;
  font-size: 17vw;
  z-index: 100;
  margin: 1%;
  position: fixed;
  left: 10%;
  font-family: "Chillax", sans-serif;
  text-shadow: rgba(224, 224, 43, 0.907) 3px 4px 1px;
  color: transparent;
  background-color: black;
  -webkit-background-clip: text;
          background-clip: text;
  top: 0;
}

.leaf-link {
  margin: 25% 10% 0;
  position: fixed;
  left: 3%;
  z-index: 10;
  background-color: transparent;
  top: 0;
}

.leaf-link:link {
  color: black;
  text-decoration: none;
  font-family: "Chillax";
  font-weight: 600;
  font-size: 3vw;
  background-color: transparent;
  border: 1px solid;
  padding: 1%;
  top: 0;
}

.thymeline {
  margin: 33% 10% 0;
  position: fixed;
  left: 3%;
  z-index: 13;
  background-color: transparent;
  top: 0;
}

.thymeline:link {
  color: black;
  text-decoration: none;
  font-family: "Chillax";
  font-weight: 600;
  font-size: 3vw;
  background-color: transparent;
  border: 1px solid;
  padding: 1%;
  top: 0;
}

.body {
  font-family: "Satoshi", sans-serif;
  font-weight: 900;
}

/* .form {
  display: inline-block;
} */

a:visited {
  color: black;
}
a:hover {
  opacity: 0.5;
  cursor: grab;
}

button:hover {
  background-color: white;
}

/* colors are place-holders */

@media only screen and (max-width: 600px) {
  .leaf-container {
    flex-direction: column;
  }

  .leaf-link:link {
    font-size: 5vw;
  }

  .thymeline:link {
    font-size: 5vw;
    margin: 36.5% 10% 0;
    position: fixed;
    left: 3%;
  }

  .login-form > #loginName {
    margin-top: 48%;
    width: 19vw;
    height: 5.75vh;
    position: fixed;
    left: 25%;
    font-size: 2.25vh;
  }
}

label {
  line-height: 30px;
  display: block;
  font-weight: 600;
}

.button {
  font-family: "Chillax";
  font-weight: 600;
  font-size: 20px;
  margin: 5% 0 0;
}

textarea {
  height: 80px;
}

html {
  font-family: "Satoshi", sans-serif;
  font-weight: 700;
}

div .form-container form {
  border-width: 3px;
  margin: 33% 25% 0;
  text-align: center;
  border-radius: 0;
  left: 10%;
}

.card {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3%;
}

#leaf-label {
  font-family: "Chillax";
  font-weight: 600;
  font-size: 25px;
  color: black;
  margin: 0 0 8%;
}

@media only screen and (max-width: 600px) {
  #leaf-label {
    font-size: 4vw;
  }

  .button {
    width: 15vw;
    font-size: 3vw;
  }

  div .form-container form {
    margin: 59% 3% 0;
    min-width: 55vw;
    max-width: 75vw;
  }
}

h3 {
  font-size: 1.5em;
  font-family: "Satoshi", sans-serif;
  -webkit-filter: drop-shadow(1.75px 0.125px 0.25px black);
          filter: drop-shadow(1.75px 0.125px 0.25px black);
  color: white;
  letter-spacing: 0.01em;
  font-weight: 900;
}
.leaf-article {
  font-size: inherit;
  border-radius: 50%;
  margin: 0.75%;
  padding: 3%;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-image: url(https://i.imgur.com/AIfsGzV.png);
  background-position: center;
  background-size: 115%;
  -webkit-filter: drop-shadow(1.5px 1.5px 1px black);
          filter: drop-shadow(1.5px 1.5px 1px black);
  min-width: 275px;
  max-width: 450px;
}

.leaf-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 7%;
}

.leaf-container > div {
  flex: 1 0;
}

img:hover {
  -webkit-filter: invert(100%);
          filter: invert(100%);
  cursor: grab;
}

@media only screen and (max-width: 600px) {
  article .leaf-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 5%;
    font-size: 5vw;

    padding: 5%;
  }

  .leaf-container > div {
    flex-direction: column;
    justify-content: center;
  }
}

/* @keyframes example {
  from {
    margin: 5%;
  }
  to {
    margin: 10%;
  }
} */

.thymeline-article h3 {
  font-size: 1.5em;
  font-family: "Satoshi", sans-serif;
  color: white;
  letter-spacing: 0.01em;
  font-weight: 900;
}

h5 {
  font-size: 0.75em;
  font-family: "Satoshi", sans-serif;
  color: white;
  letter-spacing: 0.01em;
  font-weight: 700;
  padding-top: 10px;
  position: right;
}

.thymeline-article {
  border-radius: 0;
  background-position: center;
  background-size: 150%;
  padding: 4%;
  border: 0.5px solid white;
  width: 330px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.thymeline-container > div {
  flex: 1 0;
}

.thymeline-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 25% 7% 7%;
}

#scissors {
  margin-left: 85%;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

img:hover {
  -webkit-filter: invert(100%);
          filter: invert(100%);
  cursor: grab;
}

@media only screen and (max-width: 600px) {
  .thymeline-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 3%;
    font-size: 2.5vw;
    padding: 8%;
  }

  .thymeline-container > div {
    flex-direction: column;
    justify-content: center;
  }
  img {
    width: 5vw;
  }
}

