@import url("https://api.fontshare.com/css?f[]=satoshi@300,400,900&display=swap");

h3 {
  font-size: 1.5em;
  font-family: "Satoshi", sans-serif;
  filter: drop-shadow(1.75px 0.125px 0.25px black);
  color: white;
  letter-spacing: 0.01em;
  font-weight: 900;
}
.leaf-article {
  font-size: inherit;
  border-radius: 50%;
  margin: 0.75%;
  padding: 3%;
  text-align: center;
  width: fit-content;
  background-image: url(https://i.imgur.com/AIfsGzV.png);
  background-position: center;
  background-size: 115%;
  filter: drop-shadow(1.5px 1.5px 1px black);
  min-width: 275px;
  max-width: 450px;
}

.leaf-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 7%;
}

.leaf-container > div {
  flex: 1 0 0;
}

img:hover {
  filter: invert(100%);
  cursor: grab;
}

@media only screen and (max-width: 600px) {
  article .leaf-container {
    height: fit-content;
    margin: 5%;
    font-size: 5vw;

    padding: 5%;
  }

  .leaf-container > div {
    flex-direction: column;
    justify-content: center;
  }
}
