/* @keyframes example {
  from {
    margin: 5%;
  }
  to {
    margin: 10%;
  }
} */

@import url("https://api.fontshare.com/css?f[]=satoshi@300,400,900&display=swap");

.thymeline-article h3 {
  font-size: 1.5em;
  font-family: "Satoshi", sans-serif;
  color: white;
  letter-spacing: 0.01em;
  font-weight: 900;
}

h5 {
  font-size: 0.75em;
  font-family: "Satoshi", sans-serif;
  color: white;
  letter-spacing: 0.01em;
  font-weight: 700;
  padding-top: 10px;
  position: right;
}

.thymeline-article {
  border-radius: 0;
  background-position: center;
  background-size: 150%;
  padding: 4%;
  border: 0.5px solid white;
  width: 330px;
  height: fit-content;
}

.thymeline-container > div {
  flex: 1 0 0;
}

.thymeline-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 25% 7% 7%;
}

#scissors {
  margin-left: 85%;
  transform: rotate(180deg);
}

img:hover {
  filter: invert(100%);
  cursor: grab;
}

@media only screen and (max-width: 600px) {
  .thymeline-container {
    height: fit-content;
    margin: 3%;
    font-size: 2.5vw;
    padding: 8%;
  }

  .thymeline-container > div {
    flex-direction: column;
    justify-content: center;
  }
  img {
    width: 5vw;
  }
}
