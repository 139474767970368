@import url("https://api.fontshare.com/css?f[]=satoshi@300,400,700,900&display=swap");

@import "bulma/css/bulma.css";

label {
  line-height: 30px;
  display: block;
  font-weight: 600;
}

.button {
  font-family: "Chillax";
  font-weight: 600;
  font-size: 20px;
  margin: 5% 0 0;
}

textarea {
  height: 80px;
}

html {
  font-family: "Satoshi", sans-serif;
  font-weight: 700;
}

div .form-container form {
  border-width: 3px;
  margin: 33% 25% 0;
  text-align: center;
  border-radius: 0;
  left: 10%;
}

.card {
  width: fit-content;
  padding: 3%;
}

#leaf-label {
  font-family: "Chillax";
  font-weight: 600;
  font-size: 25px;
  color: black;
  margin: 0 0 8%;
}

@media only screen and (max-width: 600px) {
  #leaf-label {
    font-size: 4vw;
  }

  .button {
    width: 15vw;
    font-size: 3vw;
  }

  div .form-container form {
    margin: 59% 3% 0;
    min-width: 55vw;
    max-width: 75vw;
  }
}
