@import url("https://api.fontshare.com/css?f[]=satoshi@300,400,900&display=swap");

@import url("https://api.fontshare.com/css?f[]=chillax@500,600,700,900&display=swap");

@import "bulma/css/bulma.css";

.login-form > #loginName {
  color: black;
  text-decoration: none;
  font-family: "Chillax";
  font-weight: 600;
  font-size: 2vw;
  padding: 0 1% 0;
  opacity: 0.5;
  border: 1px solid black;
  margin-top: 13%;
  width: 15vw;
  position: fixed;
  left: 73%;

  top: 0;
  z-index: 100;
  background-color: #ffffff90;
}

.thyme-logo h1 {
  font-weight: 900;
  font-size: 17vw;
  z-index: 100;
  margin: 1%;
  position: fixed;
  left: 10%;
  font-family: "Chillax", sans-serif;
  text-shadow: rgba(224, 224, 43, 0.907) 3px 4px 1px;
  color: transparent;
  background-color: black;
  background-clip: text;
  top: 0;
}

.leaf-link {
  margin: 25% 10% 0;
  position: fixed;
  left: 3%;
  z-index: 10;
  background-color: transparent;
  top: 0;
}

.leaf-link:link {
  color: black;
  text-decoration: none;
  font-family: "Chillax";
  font-weight: 600;
  font-size: 3vw;
  background-color: transparent;
  border: 1px solid;
  padding: 1%;
  top: 0;
}

.thymeline {
  margin: 33% 10% 0;
  position: fixed;
  left: 3%;
  z-index: 13;
  background-color: transparent;
  top: 0;
}

.thymeline:link {
  color: black;
  text-decoration: none;
  font-family: "Chillax";
  font-weight: 600;
  font-size: 3vw;
  background-color: transparent;
  border: 1px solid;
  padding: 1%;
  top: 0;
}

.body {
  font-family: "Satoshi", sans-serif;
  font-weight: 900;
}

/* .form {
  display: inline-block;
} */

a:visited {
  color: black;
}
a:hover {
  opacity: 0.5;
  cursor: grab;
}

button:hover {
  background-color: white;
}

/* colors are place-holders */

@media only screen and (max-width: 600px) {
  .leaf-container {
    flex-direction: column;
  }

  .leaf-link:link {
    font-size: 5vw;
  }

  .thymeline:link {
    font-size: 5vw;
    margin: 36.5% 10% 0;
    position: fixed;
    left: 3%;
  }

  .login-form > #loginName {
    margin-top: 48%;
    width: 19vw;
    height: 5.75vh;
    position: fixed;
    left: 25%;
    font-size: 2.25vh;
  }
}
